import { http, HttpResponse } from "msw";
import { getAppServiceUrl } from "../../../src/shared/config";
import { donors } from "../data/donorsData";
import { user, users } from "../data/systemData";
import { lookupData } from "../data/lookupData";
import { transaction } from "../data/transactionsData";
import { payoutData } from "../data/payoutData";

const handlers = [
//Lookup    
http.get(`${getAppServiceUrl()}api/v1/lookup`, ({ request, params, cookies }) => {
    return HttpResponse.json(lookupData);
}),
//User
http.post(`${getAppServiceUrl()}api/v1/user/verifyuser`, ({ request, params, cookies }) => {
    return HttpResponse.json(user);
}),
http.get(`${getAppServiceUrl()}api/v1/user/:userid`, ({ request, params, cookies }) => {
    return HttpResponse.json(user);
}),
http.get(`${getAppServiceUrl()}api/v1/user`, ({ request, params, cookies }) => {
    return HttpResponse.json(users);
}),
http.post(`${getAppServiceUrl()}api/v1/user`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"Successfully registered"}`, { status: 200 });
}),
http.put(`${getAppServiceUrl()}api/v1/user/:userId`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"User updated successfully"}`, { status: 200 });
}),
http.delete(`${getAppServiceUrl()}api/v1/user/:userId`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"User deleted successfully"}`, { status: 200 });
}),
http.get(`${getAppServiceUrl()}api/v1/user/inactive`, ({ request, params, cookies }) => {
    return HttpResponse.json([]);
}),

//Donor
http.get(`${getAppServiceUrl()}api/v1/donor`, ({ request, params, cookies }) => {
    return HttpResponse.json(donors);
}),
http.post(`${getAppServiceUrl()}api/v1/donor`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"Donor added successfully"}`, { status: 200 });
}),
http.put(`${getAppServiceUrl()}api/v1/donor/:id`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"Donor updated successfully"}`, { status: 200 });
}),
http.delete(`${getAppServiceUrl()}api/v1/donor/:id`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"Donor deleted successfully"}`, { status: 200 });
}),

//Payout
http.get(`${getAppServiceUrl()}api/v1/payout`, ({ request, params, cookies}) => {
    return HttpResponse.json(payoutData);
}),
http.post(`${getAppServiceUrl()}api/v1/payout`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"Payout added successfully"}`, { status: 200 });
}),
http.put(`${getAppServiceUrl()}api/v1/payout/:id`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"Payout updated successfully"}`, { status: 200 });
}),
http.delete(`${getAppServiceUrl()}api/v1/payout/:id`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"Payout deleted successfully"}`, { status: 200 });
}),

//Transaction
http.get(`${getAppServiceUrl()}api/v1/transaction`, ({ request, params, cookies }) => {
    return HttpResponse.json(transaction);
}),
http.get(`${getAppServiceUrl()}api/v1/transaction/:userid`, ({ request, params, cookies }) => {
    return HttpResponse.json(transaction);
}),
http.post(`${getAppServiceUrl()}api/v1/transaction`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"Transaction added successfully"}`, { status: 200 });
}),
http.put(`${getAppServiceUrl()}api/v1/transaction/:id`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"Transaction updated successfully"}`, { status: 200 });
}),
http.delete(`${getAppServiceUrl()}api/v1/transaction/:id`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"Transaction deleted successfully"}`, { status: 200 });
}),


//File
http.post(`${getAppServiceUrl()}api/v1/file`, ({ request, params, cookies }) => {
    return new HttpResponse(`{"respMsg":"File uploaded successfully"}`, { status: 200 });
}),
];

export default handlers;