const donors = [
    {
        id: "c4ec432b-3991-4616-be11-2a1f49dd699d",
        firstName: "Tom",
        lastName: "Hardy",
        email: "tomhardy@example.com",
        phoneNumber: "9856454678",
        reason: "Farming",
        version: 0
    },
    {
        id: "aadb17c2-1c11-4482-858d-ee508000cecd",
        firstName: "Rachel",
        lastName: "Hardin",
        email: "rachelhardin@example.com",
        phoneNumber: "9745636456",
        reason: "Education",
        version: 0
    },
    {
        id: "8ac975e9-6149-4e43-b732-71dbf887db96",
        firstName: "Leanardo",
        lastName: "Davinchi",
        email: "leanardo@example.com",
        phoneNumber: "9812334764",
        reason: "Education",
        version: 0
    },
    {
        id: "5db79cf8-b56e-4634-80b0-c3e5e489c3f0",
        firstName: "Chris",
        lastName: "Hemsworth",
        email: "chrishemsworth@example.com",
        phoneNumber: "9754969724",
        reason: "Land",
        version: 0
    },
    {
        id: "8e492821-899e-4a71-babb-7e2abaa25927",
        firstName: "Json",
        lastName: "Statham",
        email: "jsonstatham@example.com",
        phoneNumber: "9657699000",
        reason: "Upbringing",
        version: 0
    }
]

export {donors}