
const user = {
    id: "59ec1d4b-f561-4dd1-8973-275b2312bb43",
    firstName: "Aritra",
    lastName: "Ghosh",
    email: "aritra.emt@gmail.com",
    phoneNo: "9994431039",
    isAdmin: true,
    isActive: true
}

const users = [
    {
        id: "59ec1d4b-f561-4dd1-8973-275b2312bb43",
        firstName: "System",
        lastName: "System",
        email: "system@system.com",
        phoneNo: "9876543210",
        isAdmin: false,
        isActive: true
    },
    {
        id: "59ec1d4b-f561-4dd1-8973-275b2312bb43",
        firstName: "Aritra",
        lastName: "Ghosh",
        email: "aritra.emt@gmail.com",
        phoneNo: "9994431039",
        isAdmin: true,
        isActive: true
    }
]

export {user, users};