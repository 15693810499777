const lookupData = [
    {
        id: "c8bc7188-3fd8-11ee-be56-0242ac120002",
        code: "GN",
        text: "Initiative",
        value: "General"
    },
    {
        id: "c8bc7598-3fd8-11ee-be56-0242ac120002",
        code: "BTS",
        text: "Initiative",
        value: "Back to School"
    },
    {
        id: "c8bc77b4-3fd8-11ee-be56-0242ac120002",
        code: "HE",
        text: "Initiative",
        value: "Higher Education"
    },
    {
        id: "c8bc7caa-3fd8-11ee-be56-0242ac120002",
        code: "ICI",
        text: "Initiative",
        value: "ICInG"
    },
    {
        id: "c8bc7ec6-3fd8-11ee-be56-0242ac120002",
        code: "GF",
        text: "Initiative",
        value: "General Fund"
    },
    {
        id: "3ad49cbe-3fd9-11ee-be56-0242ac120002",
        code: "NEFT",
        text: "Payment Mode",
        value: "NEFT"
    },
    {
        id: "3ad49fd4-3fd9-11ee-be56-0242ac120002",
        code: "RTGS",
        text: "Payment Mode",
        value: "RTGS"
    },
    {
        id: "3ad4a48e-3fd9-11ee-be56-0242ac120002",
        code: "UPI",
        text: "Payment Mode",
        value: "UPI"
    },
    {
        id: "3ad4a696-3fd9-11ee-be56-0242ac120002",
        code: "CHEQUE",
        text: "Payment Mode",
        value: "CHEQUE"
    },
    {
        id: "59699e18-3fd9-11ee-be56-0242ac120002",
        code: "CASH",
        text: "Payment Mode",
        value: "CASH"
    }
]

export {lookupData};