import { sbApp } from "../build-utils/moduleConfig";

console.log("NODE_ENV", process.env.NODE_ENV);

(async function () {
  if(sbApp.startMSW){
    const { worker } = require("../msw/mocks/browser");
    await worker.start({
      onUnhandledRequest: "bypass"
    });
  }
  import("./shared/Bootstrap");
})();