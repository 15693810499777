const transaction = [
    {
        id: "1",
        donorId: "c4ec432b-3991-4616-be11-2a1f49dd699d",
        donorName: "Tom Hardy",
        date: "09/06/2023",
        initiative: "C5",
        amount: "10000",
        paymentMode: "NEFT",
        referenceNo: "SDF34565WER",
        userId: "59ec1d4b-f561-4dd1-8973-275b2312bb43"
    },
    {
        id: "2",
        donorId: "aadb17c2-1c11-4482-858d-ee508000cecd",
        donorName: "Rachel Hardin",
        date: "10/04/2023",
        initiative: "C3",
        amount: "1000",
        paymentMode: "UPI",
        referenceNo: "ERT87887NHY",
        userId: "59ec1d4b-f561-4dd1-8973-275b2312bb43"
    },
]

export {transaction};