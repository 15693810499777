export const payoutData = [
    {
        firstName: "Tom",
        lastName: "Hardy",
        email: "tom@hardy.com",
        phone: "2468273648",
        pan: "GTHJY6534F",
        mode: "NEFT",
        accNumber: "4329647298",
        bankName: "ICICI BANK",
        ifsc: "ICIC0003400",
        beneficiaryName: "Tom",
        beneficiaryPhone: "Hardy",
        initiative: "GN",
        rolePrimary: "Teacher",
        roleSecondary: ""
    }
]